import React from "react";
import App from "next/app";
import { NextPageContext } from "next";
import { Router } from "next/router";
import { ParsedUrlQuery } from "querystring";
import * as Sentry from "@sentry/node";
import { Query } from "@apollo/client/react/components";
import { ApolloProvider } from "@apollo/client";
import NProgress from "nprogress";

import { isDev, isProd, restrictedPaths } from "src/lib/environment";
if (isProd) {
  Sentry.init({
    dsn: "https://966719b81cb04aa3b139782c9470c349@sentry.io/1846715",
  });
}

if (isDev) {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, {
    hotReloadBufferMs: 1500,
    trackAllPureComponents: false,
  });
}

import HTMLHead from "src/components/layout/Head";
import {
  Navbar,
  MobileSideDrawerLinks,
  DESKTOP_NAVBAR_HEIGHT,
  MOBILE_NAV_MAX_SCREEN_WIDTH,
  MOBILE_NAVBAR_HEIGHT,
} from "src/components/layout/Header/Navbar";
import Footer from "src/components/layout/Footer";
import EarlyAccessWindow from "src/components/sales/EarlyAccessWindow";
import DiscordWidget from "src/components/DiscordWidget";

// Helpers and types
import { getSelf, meQuery } from "src/lib/graphql/user.queries";
import { User } from "src/lib/graphql/types";
import consoleMessage from "src/lib/consoleMessage";
import identifyUser from "src/lib/identifyUser";
import { trackEvent } from "src/lib/analytics";
import withApollo from "src/lib/withApollo";

import "src/styles/style.scss";
import "react-mde/lib/styles/css/react-mde-all.css";
import { ThemeProvider, Box } from "@mui/material";
import { theme } from "../styles/muiTheme";
import { CacheProvider, EmotionCache } from "@emotion/react";
import { createEmotionCache } from "src/lib/createEmotionCache";
import { HIRING_BANNER_HEIGHT } from "src/components/HiringBanner";

const clientSideEmotionCache = createEmotionCache();

type AppProps = {
  apollo: any;
  user: User;
  query: ParsedUrlQuery;
  err: Error;
  path: string;
  emotionCache?: EmotionCache;
  redirecting?: boolean;
  maintenance?: boolean;
};

type AppState = {
  earlyAccessWindow: boolean;
  mailchimpUser: any;
  mobileSidedrawerContents: null | React.ReactElement;
  prefillSignupEmail: string;
};

export type PageContext = NextPageContext & {
  user: User;
  apolloClient: any;
  cookieString: string;
};

export interface IPageProps {
  user?: User;
  signup: (mailchimpInfo?: any) => void;
  setSidedrawerContents: (contents: React.ReactElement | null) => void;
}

class MyApp extends App<AppProps, AppState> {
  static async getInitialProps({ Component, ctx }: any) {
    if (ctx.err) {
      throw ctx.err;
    }

    let redirecting = false;

    const maintenance = process.env.MAINTENANCE_MODE === "true";
    if (
      ctx.pathname !== "/maintenance" &&
      ctx.pathname !== "/legal/terms" &&
      maintenance
    ) {
      redirecting = true;
      ctx.res.writeHead(302, { Location: "/maintenance" });
      ctx.res.end();
      ctx.res.finished = true;
    }

    let pageProps = {};

    const userAgent = ctx.req?.headers["user-agent"];

    const cookieString = ctx.req ? ctx.req.cookieString : undefined;
    const path = ctx.asPath;

    let user;
    try {
      // do we have a user in the cache?
      user = ctx.apolloClient.readQuery({ query: meQuery }).me;
    } catch {
      user = await Promise.race([
        getSelf(cookieString).catch(() => null),
        // try and load the site anyway if the db doesn't respond
        new Promise((resolve) => setTimeout(resolve, 5000)),
      ]);
    }

    // write the user to the apollo cache manually
    // as we're not using ApolloClient for this initial fetching of user data
    ctx.apolloClient.writeQuery({
      query: meQuery,
      data: {
        me: user ? { ...(user as User), __typename: "User" } : null,
      },
    });

    // redirect visitors to signin if they're trying to access a restricted path
    if (
      !user &&
      (restrictedPaths.find((pathToCheck) => path.startsWith(pathToCheck)) ||
        (path.match(/\/issues\/.+/) && !path.includes("signin?")) ||
        (path.match(/\/schemas\/create-new/) && !path.includes("signin?")))
    ) {
      const redirectURL = `/signin?redirect=${
        ctx.req ? ctx.req.url : ctx.asPath
      }`;
      redirecting = true;
      if (typeof window !== "undefined") {
        window.location.replace(redirectURL);
      } else {
        ctx.res.writeHead(302, { Location: redirectURL });
        ctx.res.end();
        ctx.res.finished = true;
      }
    }

    // redirect a user to their profile page
    if (user && path === "/profile") {
      redirecting = true;
      const redirectUrl = `/@${user.shortname}`;
      if (typeof window !== "undefined") {
        window.location.replace(redirectUrl);
      } else {
        ctx.res.writeHead(302, { Location: redirectUrl });
        ctx.res.end();
        ctx.res.finished = true;
      }
    }

    // if we have a user and they're not an admin, redirect away from /admin
    if (path.startsWith("/admin") && user.role.name !== "HASHAdmin") {
      redirecting = true;
      if (typeof window !== "undefined") {
        window.location.replace("/");
      } else {
        ctx.res.writeHead(302, { Location: "/" });
        ctx.res.end();
        ctx.res.finished = true;
      }
    }

    ctx.user = user;

    if (Component.getInitialProps && !redirecting) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return {
      pageProps: {
        userAgent,
        query: ctx.query,
        ...pageProps,
      },
      user,
      query: ctx.query,
      err: ctx.err,
      path,
      redirecting,
      maintenance,
    };
  }

  state = {
    earlyAccessWindow: false,
    signinRedirect: undefined,
    mailchimpUser: undefined,
    prefillSignupEmail: undefined,
    mobileSideDrawerContents: null,
    mobileSideDrawerLinks: undefined,
  };

  componentDidMount() {
    consoleMessage();

    if (!localStorage.getItem("referrer")) {
      localStorage.setItem("referrer", window.document.referrer);
    }

    // Browser progress bar on page changes
    Router.events.on("routeChangeStart", (url) => {
      // routeChangeStart also runs on initial load,
      // so this condition prevents the initial URL being added to sessionStorage
      if (!location.href.includes(url)) {
        sessionStorage.setItem("previousRoute", location.href);
      }

      NProgress.start();
    });

    Router.events.on("routeChangeComplete", (url) => {
      NProgress.done();

      const previousRoute = sessionStorage.getItem("previousRoute");

      const isLearnPage =
        // /docs/ with trailing slash to avoid scrolling on client-side redirects from /docs to /docs/simulation
        (previousRoute?.includes("/docs/") && url?.includes("/docs")) ||
        (previousRoute?.includes("/glossary") && url?.includes("/glossary"));

      // only scroll to content if both previous and current pages are learn pages
      if (isLearnPage) {
        document
          .querySelector(".glossary-post-main-content")
          ?.scrollIntoView({ behavior: "smooth" });
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    });

    Router.events.on("routeChangeError", () => NProgress.done());

    if (window.zE) {
      window.zESettings = {
        // We're going to set custom events tracking below
        // To fit with the Category names we use in GA
        analytics: false,
        webWidget: {
          launcher: {
            mobile: {
              labelVisible: true,
            },
          },
        },
      };
    }

    const { user } = this.props;

    if (isProd) {
      if (user) {
        // analytics user identification
        identifyUser(user);
      }

      // Custom events tracking for ZE
      if (window.zE) {
        window.zE("webWidget:on", "userEvent", (event: any) => {
          trackEvent({ category: "Growth", action: event.action }, user);
        });
      }
    }

    if (this.props.query.email && !this.props.query.success) {
      this.setState({
        earlyAccessWindow: true,
        prefillSignupEmail: this.props.query.email,
      });
    }
  }

  // send additional information to Sentry on errors
  componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
    if (super.componentDidCatch) {
      super.componentDidCatch(error, errorInfo);
    }
  }

  setSideDrawerLinks(data?: MobileSideDrawerLinks) {
    this.setState({ mobileSideDrawerLinks: data });
  }

  setSideDrawerLinksProps = this.setSideDrawerLinks.bind(this);

  render() {
    const {
      Component,
      pageProps,
      apollo,
      query,
      path,
      err,
      redirecting,
      maintenance,
      emotionCache,
    } = this.props;
    const {
      earlyAccessWindow,
      mailchimpUser,
      mobileSideDrawerContents,
      prefillSignupEmail,
      signinRedirect,
      mobileSideDrawerLinks,
    } = this.state;

    // navheader theming
    let navHeaderLocation = "IndexHome";
    let showNavHeader = true;
    let showFooter = true;
    if (
      path.startsWith("/@") ||
      path.startsWith("/schemas") ||
      path.startsWith("/search") ||
      path.startsWith("/models") ||
      path.startsWith("/data") ||
      path.startsWith("/behaviors") ||
      path.startsWith("/index/search")
    ) {
      navHeaderLocation = "Index";
    } else if (path.startsWith("/unsupported")) {
      showNavHeader = false;
      showFooter = false;
    } else if (maintenance) {
      showFooter = false;
      showNavHeader = false;
    }

    if (path == "/platform/hash") {
      showFooter = false;
      showNavHeader = false;
    }

    const signup = (mailchimpUser?: any, signinRedirect?: string) =>
      this.setState({
        earlyAccessWindow: true,
        mailchimpUser,
        signinRedirect,
        prefillSignupEmail: undefined,
      });

    return (
      <ApolloProvider client={apollo}>
        <CacheProvider value={emotionCache ?? clientSideEmotionCache}>
          <ThemeProvider theme={theme}>
            <HTMLHead />
            <DiscordWidget signup={signup} />
            <Query query={meQuery}>
              {({ data }: any) => {
                const user = data?.me;

                return (
                  <>
                    {showNavHeader ? (
                      <Navbar
                        mobileSideDrawerContents={mobileSideDrawerContents}
                        mobileSideDrawerLinks={mobileSideDrawerLinks}
                        searchText={query.query?.toString()}
                        location={navHeaderLocation}
                        signup={() => signup()}
                        user={user}
                      />
                    ) : null}
                    {earlyAccessWindow && (
                      <EarlyAccessWindow
                        closeWindow={() =>
                          this.setState({
                            earlyAccessWindow: false,
                          })
                        }
                        mailchimpUser={mailchimpUser}
                        signinRedirect={signinRedirect}
                        prefillSignupEmail={prefillSignupEmail}
                      />
                    )}
                    <Box
                      sx={{
                        paddingTop: `${
                          DESKTOP_NAVBAR_HEIGHT +
                          (this.props.path === "/" ? HIRING_BANNER_HEIGHT : 0)
                        }px`,
                        [theme.breakpoints.down(MOBILE_NAV_MAX_SCREEN_WIDTH)]: {
                          paddingTop: `${
                            MOBILE_NAVBAR_HEIGHT +
                            (this.props.path === "/" ? HIRING_BANNER_HEIGHT : 0)
                          }px`,
                        },
                      }}
                    >
                      {redirecting ? (
                        <div className="login-page white content-min-height" />
                      ) : (
                        <Component
                          {...pageProps}
                          signup={(mailchimpInfo: any) => signup(mailchimpInfo)}
                          setSidedrawerContents={(
                            contents: React.ReactElement
                          ) =>
                            this.setState({
                              mobileSideDrawerContents: contents,
                            })
                          }
                          setSideDrawerLinks={this.setSideDrawerLinksProps}
                          user={user}
                          err={err}
                        />
                      )}
                    </Box>
                    {showFooter ? (
                      <Footer signup={() => signup()} user={user} />
                    ) : null}
                  </>
                );
              }}
            </Query>
          </ThemeProvider>
        </CacheProvider>
      </ApolloProvider>
    );
  }
}

export default withApollo(MyApp);
