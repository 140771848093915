import React, { FunctionComponent } from "react";
import Link from "next/link";
import { Box } from "@mui/material";

export const HIRING_BANNER_HEIGHT = 67.5;

export const HiringBanner: FunctionComponent = () => (
  <Link href="/careers">
    <a className="hiring-banner">
      <Box display="flex" alignItems="center">
        <div className="svg-icon-circle">
          <svg
            width="16px"
            height="16px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            fill="#006adc"
          >
            <path d="M480 179.6C498.6 188.4 512 212.1 512 240C512 267.9 498.6 291.6 480 300.4V448C480 460.9 472.2 472.6 460.2 477.6C448.3 482.5 434.5 479.8 425.4 470.6L381.7 426.1C333.7 378.1 268.6 352 200.7 352H192V480C192 497.7 177.7 512 160 512H96C78.33 512 64 497.7 64 480V352C28.65 352 0 323.3 0 288V192C0 156.7 28.65 128 64 128H200.7C268.6 128 333.7 101 381.7 53.02L425.4 9.373C434.5 .2215 448.3-2.516 460.2 2.437C472.2 7.39 480 19.06 480 32V179.6zM200.7 192H192V288H200.7C280.5 288 357.2 317.8 416 371.3V108.7C357.2 162.2 280.5 192 200.7 192V192z" />
          </svg>
        </div>
        <Box
          component="p"
          sx={{ fontWeight: 600, marginLeft: "0.75rem", marginY: 0 }}
        >
          We're hiring!
        </Box>
      </Box>
      <span className="big-screens-only">
        {
          "HASH is a venture-backed startup building tools to improve decision-making."
        }
      </span>
      <button className="roles-button">{"View open roles"}</button>
      <style jsx>{`
        transition: all 0.5s;
        .hiring-banner {
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(
            120deg,
            hsl(210, 85%, 50%) 0%,
            hsl(211, 100%, 43.2%) 100%
          );
          color: white;
          width: 100%;
          text-align: center;
          font-weight: 400;
          font-size: 0.95rem;
          grid-gap: 1rem;
          cursor: pointer;
          padding: 1rem 0.75rem;
        }

        .hiring-banner:hover {
          background: linear-gradient(
            120deg,
            hsl(210, 85%, 60%) -20%,
            hsl(211, 100%, 50%) 70%
          );
        }

        .svg-icon-circle {
          width: 2rem;
          height: 2rem;
          background: white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
        }

        .roles-button {
          padding: 0.5rem 0.75rem;
          background: transparent;
          color: #fff;
          border: 1px solid hsla(0, 0%, 100%, 0.3);
          font-weight: 500;
        }

        @media (max-width: 930px) {
          .hiring-banner {
            grid-gap: 0.5rem;
            justify-content: space-between;
          }

          .big-screens-only {
            display: none;
          }
        }
      `}</style>
    </a>
  </Link>
);
