import slugify from "slugify";
import { Project } from "./graphql/types";

export const restrictedPaths = ["/account", "/admin", "/profile"];

export const baseS3URL = "https://cdn-us1.hash.ai";

export const isDev =
  process.env.NODE_ENV === "development" ||
  process.env.NEXT_PUBLIC_API_URL?.includes("devapi") ||
  process.env.NEXT_PUBLIC_API_URL?.includes("localhost") ||
  (typeof window === "undefined" && !process.env.NEXT_PUBLIC_API_URL) ||
  (typeof window !== "undefined" && !["hash.ai", "simulation.hash.ai"].includes(window.location.hostname));

export const testEnv = process.env.NODE_ENV === "test";

export const isProd = !isDev;

export const coreUrl = isDev
  ? "https://staging.hash.ai"
  : "https://core.hash.ai";

export const apiUrl = process.env?.NEXT_PUBLIC_API_URL
  ? process.env.NEXT_PUBLIC_API_URL
  : `https://${isDev ? "devapi" : "api"}.hash.ai`;

export const graphqlUrl = `${apiUrl}/graphql`;

export const simLink = (
  project: Pick<Project, "pathWithNamespace">,
  ref?: string
) => `${coreUrl}/${project.pathWithNamespace}` + (ref ? `/${ref}` : "/main");

export const indexListingLink = (id: string, title?: string) => {
  let link = `/index/${id}/`;
  if (title) {
    const encodedTitle = encodeURIComponent(
      slugify(title, {
        lower: true,
        strict: true,
      })
    );
    link += encodedTitle;
  }
  return link;
};
