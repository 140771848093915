import React, { memo, FunctionComponent } from "react";
import Link from "next/link";

import { User } from "src/lib/graphql/types";
import { trackEvent } from "src/lib/analytics";

import FacebookIcon from "../icons/FacebookIcon";
import TwitterIconGrey from "../icons/TwitterIconGrey";
import LinkedInIconGrey from "../icons/LinkedInIconGrey";
import Logo from "../logos/HashLogo";
import GitHubIcon from "../icons/GitHubIcon";

type FooterLink = {
  text: string;
  href: string;
  external?: boolean;
};

type HeaderLink = {
  text: string;
  href?: string;
  external?: boolean;
};

const linkGroups: Array<{
  header: HeaderLink;
  links: FooterLink[];
  className?: string;
}> = [
  {
    header: {
      text: "HASH Platform",
      href: "/platform",
    },
    links: [],
    className: "platform",
  },
  {
    header: {
      text: "Block Protocol",
      href: "https://blockprotocol.org",
      external: true,
    },
    links: [
      {
        text: "Documentation",
        href: "https://blockprotocol.org/docs",
        external: true,
      },
      {
        text: "Specification",
        href: "https://blockprotocol.org/spec",
        external: true,
      },
      {
        text: "FAQ",
        href: "https://blockprotocol.org/docs/faq",
        external: true,
      },
    ],
    className: "bp",
  },
  {
    header: {
      text: "Simulations",
      href: "/simulations",
    },
    links: [
      {
        text: "hEngine",
        href: "/platform/engine",
      },
      {
        text: "hCore",
        href: "/platform/core",
      },
      {
        text: "Documentation",
        href: "/docs/simulation",
      },
      {
        text: "Use Cases",
        href: "/cases/optimization",
      },
      {
        text: "Glossary",
        href: "/glossary",
      },
      {
        text: "Discord",
        href: "/discord",
      },
    ],
    className: "simulations",
  },
  {
    header: {
      text: "Company",
    },
    links: [
      {
        text: "About",
        href: "/about",
      },
      {
        text: "Careers",
        href: "/careers",
      },
      {
        text: "Blog",
        href: "/blog",
      },
      {
        text: "Contact Us",
        href: "/contact",
      },
    ],
    className: "company",
  },
];

type FooterProps = {
  signup: () => void;
  user?: User;
};

const preventRender = (prevProps: FooterProps, nextProps: FooterProps) => {
  if (
    (prevProps.user && !nextProps.user) ||
    (!prevProps.user && nextProps.user)
  ) {
    return false;
  }
  return true;
};

const Footer: FunctionComponent<FooterProps> = memo(({ user }) => {
  return (
    <div className="footer">
      <div className="container">
        {/* Logo */}
        <div className="footer-logo">
          <Link href="/">
            <a>
              <Logo size={1.5} color="white" letters />
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
}, preventRender);

export default Footer;
