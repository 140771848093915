import axios, { AxiosRequestConfig } from "axios";

import { graphqlUrl } from "../environment";

const axiosConfig: AxiosRequestConfig = { withCredentials: true };
const axiosInstance = axios.create(axiosConfig);

type GQLResponse = {
  data: any;
  errors: Array<{
    message: string;
    locations: {
      line: number;
      column: number;
    };
  }>;
  extensions: {
    code: string;
    exception: any;
    stacktrace: any;
  };
};

export type queryResponse = {
  data: any;
};

const query = async (
  query: string,
  variables?: any,
  Cookie?: string
): Promise<queryResponse> => {
  const headers: any = {
    "Content-Type": "application/json",
  };
  if (Cookie) {
    headers.Cookie = Cookie;
  }

  const response: GQLResponse = await axiosInstance.post(
    graphqlUrl,
    { query, variables },
    { withCredentials: true, headers }
  );

  const { errors, data } = response.data;

  if (errors) throw new Error(errors[0].message);

  return { data };
};

export default query;
