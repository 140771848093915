import variables from "./scss/variables.module.scss";

export const scssVariables: SCSSVariables = variables as any;

export type SCSSVariables = {
  fontXs: string;
  fontSm: string;
  fontBase: string;
  fontMd: string;
  fontLg: string;
  fontXl: string;
  fontXxl: string;
  fontXxxl: string;
  leadingTight: string;
  leadingSnug: string;
  leadingBase: string;
  leadingLoose: string;
  space1: string;
  space2: string;
  space3: string;
  space4: string;
  space5: string;
  space6: string;
  space7: string;
  space8: string;
  space9: string;
  space10: string;
  transitionBase: string;
  transitionFast: string;
  transitionSlow: string;
  white: string;
  gray1: string;
  gray2: string;
  gray3: string;
  gray4: string;
  gray5: string;
  gray6: string;
  gray7: string;
  gray8: string;
  gray9: string;
  gray10: string;
  gray11: string;
  gray12: string;
  gray13: string;
  gray14: string;
  blue1: string;
  blue2: string;
  blue3: string;
  blue4: string;
  blue5: string;
  blue6: string;
  blue7: string;
  blue8: string;
  blue9: string;
  blue10: string;
  blue11: string;
  blue12: string;
  purple1: string;
  purple2: string;
  purple3: string;
  purple4: string;
  purple5: string;
  purple6: string;
  purple7: string;
  purple8: string;
  purple9: string;
  purple10: string;
  purple11: string;
  purple12: string;
  red1: string;
  red2: string;
  red3: string;
  red4: string;
  red5: string;
  red6: string;
  red7: string;
  red8: string;
  red9: string;
  red10: string;
  red11: string;
  red12: string;
  orange1: string;
  orange2: string;
  orange3: string;
  orange4: string;
  orange5: string;
  orange6: string;
  orange7: string;
  orange8: string;
  orange9: string;
  orange10: string;
  orange11: string;
  orange12: string;
  green1: string;
  green2: string;
  green3: string;
  green4: string;
  green5: string;
  green6: string;
  green7: string;
  green8: string;
  green9: string;
  green10: string;
  green11: string;
  green12: string;
  pink1: string;
  pink2: string;
  pink3: string;
  pink4: string;
  pink5: string;
  pink6: string;
  pink7: string;
  pink8: string;
  pink9: string;
  pink10: string;
  pink11: string;
  pink12: string;
  borderRadius1: string;
  borderRadius2: string;
  borderRadius3: string;
  borderRadius4: string;
  shadowLow: string;
  shadowMedium: string;
  shadowHigh: string;
  shadowLowBlue: string;
  shadowMediumBlue: string;
  shadowHighBlue: string;
};
