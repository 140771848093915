import { Button, styled, experimental_sx, buttonClasses } from "@mui/material";

export const NavButton = styled(Button)(({ theme }) =>
  experimental_sx({
    lineHeight: 1.2,
    fontFamily: "inherit",
    padding: theme.spacing(1, 1.5),
    borderRadius: 1,
    letterSpacing: 0,
    textTransform: "none",
    color: "#37434F",
    ":hover": {
      backgroundColor: "#EBF2F7",
    },
    [`.${buttonClasses.endIcon}, .${buttonClasses.startIcon}`]: {
      color: "#758AA1",
    },
  })
);
