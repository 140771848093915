import { createTheme } from "@mui/material/styles";
import { ThemeOptions } from "@mui/material";
import { scssVariables } from "./scssVariables";

const palette: ThemeOptions["palette"] = {
  gray: {
    1: scssVariables.gray1,
    2: scssVariables.gray2,
    3: scssVariables.gray3,
    4: scssVariables.gray4,
    5: scssVariables.gray5,
    6: scssVariables.gray6,
    7: scssVariables.gray7,
    8: scssVariables.gray8,
    9: scssVariables.gray9,
    10: scssVariables.gray10,
    11: scssVariables.gray11,
    12: scssVariables.gray12,
    13: scssVariables.gray13,
    14: scssVariables.gray14,
  },
  blue: {
    1: scssVariables.blue1,
    2: scssVariables.blue2,
    3: scssVariables.blue3,
    4: scssVariables.blue4,
    5: scssVariables.blue5,
    6: scssVariables.blue6,
    7: scssVariables.blue7,
    8: scssVariables.blue8,
    9: scssVariables.blue9,
    10: scssVariables.blue10,
    11: scssVariables.blue11,
    12: scssVariables.blue12,
  },
  purple: {
    1: scssVariables.purple1,
    2: scssVariables.purple2,
    3: scssVariables.purple3,
    4: scssVariables.purple4,
    5: scssVariables.purple5,
    6: scssVariables.purple6,
    7: scssVariables.purple7,
    8: scssVariables.purple8,
    9: scssVariables.purple9,
    10: scssVariables.purple10,
    11: scssVariables.purple11,
    12: scssVariables.purple12,
  },
  red: {
    1: scssVariables.red1,
    2: scssVariables.red2,
    3: scssVariables.red3,
    4: scssVariables.red4,
    5: scssVariables.red5,
    6: scssVariables.red6,
    7: scssVariables.red7,
    8: scssVariables.red8,
    9: scssVariables.red9,
    10: scssVariables.red10,
    11: scssVariables.red11,
    12: scssVariables.red12,
  },
  orange: {
    1: scssVariables.orange1,
    2: scssVariables.orange2,
    3: scssVariables.orange3,
    4: scssVariables.orange4,
    5: scssVariables.orange5,
    6: scssVariables.orange6,
    7: scssVariables.orange7,
    8: scssVariables.orange8,
    9: scssVariables.orange9,
    10: scssVariables.orange10,
    11: scssVariables.orange11,
    12: scssVariables.orange12,
  },
  green: {
    1: scssVariables.green1,
    2: scssVariables.green2,
    3: scssVariables.green3,
    4: scssVariables.green4,
    5: scssVariables.green5,
    6: scssVariables.green6,
    7: scssVariables.green7,
    8: scssVariables.green8,
    9: scssVariables.green9,
    10: scssVariables.green10,
    11: scssVariables.green11,
    12: scssVariables.green12,
  },
  pink: {
    1: scssVariables.pink1,
    2: scssVariables.pink2,
    3: scssVariables.pink3,
    4: scssVariables.pink4,
    5: scssVariables.pink5,
    6: scssVariables.pink6,
    7: scssVariables.pink7,
    8: scssVariables.pink8,
    9: scssVariables.pink9,
    10: scssVariables.pink10,
    11: scssVariables.pink11,
    12: scssVariables.pink12,
  },
};

export const theme = createTheme({
  palette,
  /** @todo: integrate these variables into the MUI theme system */
  scssVariables: scssVariables,

  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});
