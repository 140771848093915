import gql from "graphql-tag";

export const projectCardFieldsFragment = gql`
  fragment ProjectCardFields on Project {
    __typename
    id
    name
    visibility
    avatar
    thumbnail
    namespace
    path
    pathWithNamespace
    description
    type
    forkCount
    starCount
    popularity
    createdAt
    updatedAt
    owner {
      __typename
      ... on Org {
        id
        avatar
      }
      ... on User {
        id
        image
      }
    }
  }
`;

export const userOrOrgProjectResultsFragment = gql`
  fragment UserOrOrgProjectResultsFields on UserOrOrgProjectResults {
    __typename
    totalCount
    page
    types
    sort
    results {
      ...ProjectCardFields
    }
  }
  ${projectCardFieldsFragment}
`;

export const orgFieldsFragment = gql`
  fragment OrgFields on Org {
    __typename
    id
    name
    alternateName
    canUserEdit
    address {
      __typename
      postOfficeBoxNumber
      streetAddress
      addressLocality
      addressRegion
      addressCountry
      postalCode
    }
    location
    description
    legalName
    shortname
    members {
      __typename
      id
      image
      givenName
      familyName
      fullName
      shortname
      memberOf {
        __typename
        org {
          __typename
          id
        }
        jobTitle
        role {
          __typename
          id
          name
        }
      }
    }
    parentOrganization {
      __typename
      id
      name
      shortname
      logo
    }
    roles {
      __typename
      id
      name
    }
    subOrganization {
      __typename
      id
      name
      shortname
      logo
    }
    nonProfit
    supportContact
    publicMembership
    logo
    avatar
    image
    url
    createdAt
    updatedAt
  }
`;

export const userFields = `
  id
  givenName
  familyName
  fullName
  shortname
  biography
  location
  url
  lastLogin
  paymentMethods {
    __typename
    id
    brand
    last4
    default
    expiry
  }
  invoices {
    __typename
    total
    number
    periodStart
    periodEnd
    created
    status
    clientSecret
  }
  upcomingInvoice {
    __typename
    total
    number
    periodStart
    periodEnd
    created
    status
  }
  knowsLanguage
  memberOf {
    __typename
    org {
      __typename
      id
      name
      shortname
      avatar
      logo
      memberCount
    }
    role {
      __typename
      id
      name
    }
    jobTitle
  }
  telephone
  image
  email
  role {
    __typename
    id
    name
  }
  staffMember
  createdAt
  updatedAt
`;

/**
 * @todo Figure out why interpolating userFields into this fragment errors
 */
export const userFieldsFragment = gql`
  fragment UserFields on User {
    id
    givenName
    familyName
    fullName
    shortname
    biography
    location
    url
    lastLogin
    paymentMethods {
      __typename
      id
      brand
      last4
      default
      expiry
    }
    invoices {
      __typename
      total
      number
      periodStart
      periodEnd
      created
      status
      clientSecret
    }
    upcomingInvoice {
      __typename
      total
      number
      periodStart
      periodEnd
      created
      status
    }
    knowsLanguage
    memberOf {
      __typename
      org {
        __typename
        id
        name
        shortname
        avatar
        logo
        memberCount
      }
      role {
        __typename
        id
        name
      }
      jobTitle
    }
    telephone
    image
    email
    role {
      __typename
      id
      name
    }
    staffMember
    createdAt
    updatedAt
  }
`;

export const projectFieldsFragment = gql`
  fragment ProjectFields on Project {
    id
    name
    path
    namespace
    pathWithNamespace
    downloadUrl
    visibility
    trusted
    type
    archived
    description
    readme
    avatar
    thumbnail
    languages
    languageSplit {
      language
      percentage
    }
    image
    keywords
    license {
      id
      name
      description
      logo
      url
    }
    subject {
      id
      name
      namespace
      updatedAt
    }
    owner {
      __typename
      ... on User {
        id
        shortname
        fullName
        image
      }
      ... on Org {
        id
        shortname
        name
        avatar
      }
    }
    ownerType
    createdAt
    updatedAt
    forkCount
    forkOf {
      namespace
      path
      pathWithNamespace
    }
    starCount
    dependents
    popularity
    canUserEdit
    temporalCoverage
    spatialCoverage
    dataFrequency
    source
    landingPage
  }
`;
