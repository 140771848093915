import React from "react";
import Link from "next/link";
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";
import sanitize from "sanitize-html";
import { format } from "date-fns";
import { decode } from "he";

import { WordPressPost } from "./wp";
import { ReactSelectOption } from "./globalTypes";

export const sanitizeUrl = (url: string) => {
  const results = sanitize(`<a href="${url}" />`, {
    allowedAttributes: {
      a: ["href"],
    },
    allowedSchemes: ["http", "https"],
  });
  return results.split('"')?.[1] || "";
};

export const wordPressPostExcerpt = (post?: WordPressPost) => {
  if (!post?.excerpt?.rendered) {
    return "Build simulations in minutes not days. HASH is an open-source platform backed and built by the founders of Kaggle, Stack Overflow, Trello and Glitch.";
  }
  return decode(post?.excerpt?.rendered || "").replace(/(\<p\>|\<\/p\>)/g, "");
};

export const parseHTML = (html: any) => {
  let keyConceptsHeader: any;
  const transform = (node: any, index: number) => {
    if (
      node.type === "tag" &&
      node.name === "a" &&
      (node.attribs.href.startsWith("/") ||
        node.attribs.href.startsWith("https://hash.ai/"))
    ) {
      return (
        <Link
          key={index}
          href={
            node.attribs.href.startsWith("https://hash.ai/")
              ? node.attribs.href.replace("https://hash.ai/", "/")
              : node.attribs.href
          }
        >
          {convertNodeToElement(node, index, transform)}
        </Link>
      );
    }

    if (node.type === "tag" && node.name === "iframe") {
      return (
        <div className="embed-container">
          <iframe src={node.attribs.src} frameBorder="0" />
        </div>
      );
    }

    if (node.attribs?.class === "key-concepts-header") {
      keyConceptsHeader = node;
      return null;
    } else if (node.attribs?.class === "key-concepts-body") {
      const element = (
        <div className="key-concepts" key={index}>
          {convertNodeToElement(keyConceptsHeader, index - 1, transform)}
          {convertNodeToElement(node, index, transform)}
        </div>
      );
      keyConceptsHeader = undefined;
      return element;
    }
  };
  return ReactHtmlParser(html, { transform });
};

export const paramsToString = (params: any): string => {
  let paramString = "";
  let count = 0;
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (!value) {
      return;
    }
    if (count > 0) {
      paramString += "&";
    }
    if (value instanceof Array) {
      for (let i = 0; i < value.length; i++) {
        paramString += `${key}=${encodeURIComponent(value[i])}`;
        if (i + 1 < value.length) {
          paramString += "&";
        }
      }
      count++;
      return;
    }
    paramString += `${encodeURIComponent(key)}=${encodeURIComponent(
      params[key]
    )}`;
    count++;
  });
  return paramString;
};

const months: string[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const nth = (d: number): string => {
  if (d > 3 && d < 21) {
    return "th";
  }
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const dateToReadableTextDate = (
  inputDate: string | Date,
  dateOnly?: boolean
) => {
  const dateObject = new Date(inputDate);

  const date: number = dateObject.getDate();
  const month: string = months[dateObject.getMonth()];
  const year: number = dateObject.getFullYear();

  let hours: number = dateObject.getHours();
  let minutes: string | number = dateObject.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours === 12 ? 12 : hours % 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;

  let dateString = `${date + nth(date)} ${month} ${year}`;

  if (!dateOnly) {
    dateString += ` at ${hours}:${minutes + ampm}`;
  }

  return dateString;
};

export const daythMonth = (date: string | Date) => {
  const d = new Date(date);
  return `${d.getDate()}${nth(d.getDate())} ${months[d.getMonth()]}`;
};

export const formatTimeDate = (date: Date | string) => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  return format(date, "HH:mm yyyy-MM-dd");
};

export const formatDateOnly = (date: Date | string) => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  return format(date, "yyyy-MM-dd");
};

export const numberWithCommas = (n: number) => {
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const slugToTitleCase = (slug: string) =>
  slug
    .replace(
      /\b\w+/g,
      (str) => str[0].toUpperCase() + str.slice(1).toLowerCase()
    )
    .replace(/[^a-zA-Z\d:]/g, " ");

export const truncateText = (text: string, chars: number): string => {
  if (!text) {
    return "";
  }
  if (text.length <= chars) {
    return text;
  } else {
    for (let i = chars; i > 0; i--) {
      if (
        text.charAt(i) === " " &&
        (text.charAt(i - 1) !== "," ||
          text.charAt(i - 1) !== "." ||
          text.charAt(i - 1) !== ";")
      ) {
        return text.substring(0, i) + "...";
      }
    }
    return text.slice(0, chars - 3) + "...";
  }
};

export const BToMB = (bytes: number | string | undefined) => {
  if (!bytes) {
    return "";
  }
  if (typeof bytes === "string") {
    bytes = parseInt(bytes, 10);
  }
  const KB = bytes / 1024;
  const MB = KB / 1024;
  const GB = MB / 1024;
  if (KB < 1000) {
    return `${Math.round(KB)}KB`;
  } else if (MB < 1000) {
    return `${MB < 20 ? MB.toFixed(1) : bigNumberSeparated(Math.round(MB))}MB`;
  } else {
    return `${GB < 20 ? GB.toFixed(1) : bigNumberSeparated(Math.round(GB))}GB`;
  }
};

export const bigNumberSeparated = (int: number) => {
  return int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const typeToExt = (type: string) => {
  if (!type) {
    return "";
  }
  return "." + type.split("/")[1] || "";
};

export const distinct = (array: any[]) =>
  array.filter((value, index, arr) => arr.indexOf(value) === index);

export const setShortnameValue = (
  value: string,
  ext?: string,
  restrictLength: boolean = true
) => {
  const allowed = /^[a-zA-Z0-9-_]+$/;
  if (ext) {
    value = value.split(".")[0];
  }
  if (value[0] === "@") {
    value = value.slice(1);
  } else {
    value = value;
  }
  if (restrictLength && value.length > 24) {
    return { error: "Only 24 characters maximum" };
  }
  if (value[0] === "-") {
    return { error: "Cannot start with -" };
  }
  if (value !== "" && value.search(allowed)) {
    return { error: "Only letters, numbers, - and _ permitted" };
  } else {
    return {
      value: ext ? value + ext : value,
    };
  }
};

export const removeUrlPrefix = (url: string) => {
  return url.replace(/^https?:\/\/(www\d*.)?/i, "").replace(/\/$/, "");
};

export function linkShortnames(linkStyle: "markdown", text?: string): string;
export function linkShortnames(
  linkStyle: "html",
  text?: string
): JSX.Element | string;
export function linkShortnames(linkStyle: "markdown" | "html", text?: string) {
  if (!text) {
    return "";
  }

  const parts: string[] = text.split(/( @[a-zA-Z0-9-_]+(?=\s|\.|\,|$))/g);

  for (let i = 1; i < parts.length; i += 2) {
    parts[i] =
      linkStyle === "markdown"
        ? ` [${parts[i].slice(1)}](/${parts[i].slice(1)})`
        : `<a href="https://hash.ai/${parts[i]}" class="text-link">
          ${parts[i]}
        </a>`;
  }

  if (linkStyle === "markdown") {
    return parts.join("");
  } else {
    // @TODO - figure out safe way of linking html
    return "";
  }
}

export const toReactSelectOption = (
  value: string | number
): ReactSelectOption => ({
  label: value.toString(),
  value: value.toString(),
});
