const bucket = "images.hash.ai";
const baseUrl = "https://dmh69e51sis2l.cloudfront.net";

export function toJpeg(originalUrl: string, height?: number): string;
export function toJpeg(
  originalUrl?: string,
  height?: number
): string | undefined;
export function toJpeg(originalUrl?: string, height: number = 300) {
  if (!originalUrl) {
    return undefined;
  }

  const key = originalUrl.split("images.hash.ai/")[1];
  if (!key || key.endsWith("svg") || key.endsWith("png")) {
    return originalUrl;
  }

  // https://docs.aws.amazon.com/solutions/latest/serverless-image-handler/deployment.html#step2
  // See https://sharp.pixelplumbing.com/ for image transformation options

  const imageRequest = JSON.stringify({
    bucket,
    key,
    edits: {
      resize: {
        height,
      },
      toFormat: "jpeg",
      webp: {
        quality: 90,
      },
    },
  });

  const compressedUrl = `${baseUrl}/${Buffer.from(imageRequest).toString(
    "base64"
  )}`;

  return compressedUrl;
}
