import React, { FunctionComponent, useRef } from "react";
import { apiUrl } from "src/lib/environment";
import { useUser } from "src/hooks/useUser";
import { toJpeg } from "src/lib/imageCompressor";

import DropdownIcon from "../../icons/DropdownIcon";
import { Box, ClickAwayListener, Fade, Paper, Popper } from "@mui/material";
import { NavButton } from "./shared/NavButton";
import { isEventTargetANode } from "./shared/util";
import { NAVBAR_Z_INDEX } from "./Navbar";
import { TextLink } from "./shared/TextLink";

const AccountDropdown: FunctionComponent<{
  desktopAccountDropdownIsOpen: boolean;
  setDesktopAccountDropdownIsOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}> = ({ desktopAccountDropdownIsOpen, setDesktopAccountDropdownIsOpen }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const close = () => setDesktopAccountDropdownIsOpen(false);

  const [user] = useUser();

  return (
    <Box>
      <NavButton
        ref={buttonRef}
        onClick={() =>
          setDesktopAccountDropdownIsOpen(!desktopAccountDropdownIsOpen)
        }
        startIcon={
          user?.image && (
            <Box
              component="img"
              src={toJpeg(user.image, 100)}
              alt={`${user.shortname}'s account`}
              sx={{
                height: "22px",
                width: "22px",
                borderRadius: "50%",
                border: "1px solid hsl(205, 13%, 68%)",
                objectFit: "cover",
                marginRight: "10px",
              }}
            />
          )
        }
        endIcon={<DropdownIcon />}
      >
        Account
      </NavButton>
      <ClickAwayListener
        onClickAway={({ target }) => {
          const isButtonClick =
            isEventTargetANode(target) && buttonRef.current?.contains(target);

          if (!isButtonClick && desktopAccountDropdownIsOpen) {
            setDesktopAccountDropdownIsOpen(false);
          }
        }}
      >
        <Popper
          open={desktopAccountDropdownIsOpen}
          anchorEl={buttonRef.current}
          keepMounted
          transition
          popperOptions={{
            strategy: "fixed",
            modifiers: [
              { name: "computeStyles", options: { adaptive: false } },
            ],
          }}
          placement={"bottom-start"}
          sx={{
            zIndex: NAVBAR_Z_INDEX + 1,
          }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                sx={{
                  padding: 3,
                  marginTop: 0.5,
                  borderRadius: "6px",
                  borderColor: "#DDE7F0",
                  borderWidth: 1,
                  borderStyle: "solid",
                  backgroundColor: "#fff",
                  boxShadow:
                    "0px 51px 87px rgba(50, 65, 111, 0.07), 0px 33.0556px 50.9514px rgba(50, 65, 111, 0.0531481), 0px 19.6444px 27.7111px rgba(50, 65, 111, 0.0425185), 0px 10.2px 14.1375px rgba(50, 65, 111, 0.035), 0px 4.15556px 7.08889px rgba(50, 65, 111, 0.0274815), 0px 0.944444px 3.42361px rgba(50, 65, 111, 0.0168519)",
                  "> a:not(:last-child)": {
                    marginBottom: 0.75,
                  },
                }}
              >
                <TextLink href={`/@${user?.shortname}`} onClick={close}>
                  Profile
                </TextLink>
                {user?.role?.name === "HASHAdmin" && (
                  <TextLink href="/admin/users" onClick={close}>
                    Admin
                  </TextLink>
                )}
                <TextLink
                  href={`${apiUrl}/logout`}
                  onClick={() => {
                    close();
                  }}
                >
                  Log out
                </TextLink>
              </Paper>
            </Fade>
          )}
        </Popper>
      </ClickAwayListener>
    </Box>
  );
};

export default AccountDropdown;
