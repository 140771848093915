import React, { FunctionComponent } from "react";

import HashLettersBlack from "../../../public/assets/hash-black-letters.svg";
import HashLettersWhite from "../../../public/assets/hash-white-letters.svg";

type LogoColors = "black" | "white";

type LogoProps = {
  size: number;
  color?: LogoColors;
  letters?: boolean;
  mono?: boolean;
};

const Logo: FunctionComponent<LogoProps> = ({ size, color, letters, mono }) => (
  <div className={"logo-component flex" + (mono ? " mono" : "")}>
    <div style={{ width: `${size}rem`, height: `${size}rem` }}>
      <div className="vertical1"></div>
      <div className="vertical2"></div>
      <div className="horizontal1"></div>
      <div className="horizontal2"></div>
    </div>
    {letters &&
      (color === "black" ? (
        <HashLettersBlack
          className={"hash-letters " + color}
          style={{
            height: `calc(${size}rem)`,
            marginLeft: `calc(${size}rem*0.5)`,
            width: "auto",
          }}
        />
      ) : (
        <HashLettersWhite
          className={"hash-letters " + color}
          style={{
            height: `calc(${size}rem)`,
            marginLeft: `calc(${size}rem*0.5)`,
            width: "auto",
          }}
        />
      ))}
  </div>
);

export default Logo;
