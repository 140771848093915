import React from "react";

import TwitterIcon from "../icons/TwitterIcon";
import DiscordIcon from "../icons/DiscordIcon";
import { DISCORD_URL } from "../DiscordWidget";

const CompletedRegistrationButtons = () => {
  return (
    <div className="twitter-and-discord-button">
      <a
        href="https://twitter.com/hashintel"
        target="_blank"
        rel="noreferrer noopener"
      >
        <button className="white-button svg twitter">
          Follow us on Twitter
          <TwitterIcon />
        </button>
      </a>
      <a href={DISCORD_URL} target="_blank" rel="noreferrer noopener">
        <button className="white-button svg discord">
          Join our Discord
          <DiscordIcon />
        </button>
      </a>
    </div>
  );
};

export default CompletedRegistrationButtons;
