import { User } from "./graphql/types";
import * as Sentry from "@sentry/browser";

const identifyUser = (user: User) => {
  Sentry.setUser({
    id: user.id,
    email: user.email,
    username: user.shortname,
  });
  if (window.zE) {
    window.zE("webWidget", "prefill", {
      name: {
        value: user.givenName
          ? user.familyName
            ? `${user.givenName} ${user.familyName}`
            : user.givenName
          : "",
      },
      email: {
        value: user.email,
        readOnly: true,
      },
    });
  }
};

export default identifyUser;
