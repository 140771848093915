import React, { FC, useEffect, useRef, useState } from "react";

import classnames from "classnames";
import { useRouter } from "next/router";

import { useUser } from "src/hooks/useUser";
import DiscordIcon from "./icons/DiscordIcon";

export const DISCORD_URL = "https://discord.gg/BPMrGAhjPh";

type DiscordWidgetProps = {
  autoRedirect?: boolean;
  signup: () => void;
  textLink?: boolean;
};

const DiscordWidget: FC<DiscordWidgetProps> = ({
  autoRedirect,
  // signup,
  textLink,
}) => {
  const [user] = useUser();
  const [mobile, setMobile] = useState(false);

  const signinRedirect = useRef<boolean>(false);
  const router = useRouter();

  useEffect(() => {
    if (navigator?.userAgent?.match(/mobi/i)) {
      setMobile(true);
    }
  }, []);

  useEffect(() => {
    if (!user) {
      signinRedirect.current = true;
    }
  }, [user]);

  useEffect(() => {
    if (!autoRedirect) {
      return;
    }
    if (signinRedirect.current) {
      router.replace("/signup?redirect=/discord");
    }
    if (user) {
      window.location.replace(DISCORD_URL);
    }
  }, [autoRedirect, router, user]);

  const onClick = () => {
    window.open(DISCORD_URL, "_blank");
    // Login gate for accessing Discord temporarily removed
    // if (!user) {
    //   signup();
    // } else {
    //   window.open(DISCORD_URL, "_blank");
    // }
  };

  if (textLink) {
    return (
      <a className="text-link" onClick={onClick}>
        Discord
      </a>
    );
  }

  const classes = classnames("discord-widget", { mobile });

  return (
    <div className={classes} onClick={onClick}>
      {textLink ? "Discord" : <DiscordIcon />}
    </div>
  );
};

export default DiscordWidget;
