import { isProd } from "./environment";
import { User } from "./graphql/types";

// Send event to Google Tag Manager
// A trigger per event name must be set up in GTM for forwarding to Google Analytics
export const trackEvent = (
  googleAnalyticsEvent: {
    category: "Growth" | "Activity";
    action: string;
    label?: string;
  },
  user?: User | null,
) => {
  // Don't track events if not in production or attempt if not in browser
  // (there shouldn't be any events fired on a server render anyway)
  if (!isProd || typeof window === "undefined") {
    return;
  }

  // Don't track events for staff members
  if (user?.staffMember) {
    return;
  }

  const { category, action, label } = googleAnalyticsEvent;

  window.dataLayer?.push(
    {
      event: action,
      category,
      label,
      client: "hash.ai"
    }
  );
};
