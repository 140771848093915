import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import { setShortnameValue } from "src/lib/functions";

export type CreateAccountInput = {
  givenName: string;
  familyName: string;
  shortname: string;
};

type CreateAccountFormProps = {
  submit: (input: CreateAccountInput) => void;
  error?: string;
  disableForm?: boolean;
  knownGivenName?: string;
  knownFamilyName?: string;
};

const CreateAccountForm: FunctionComponent<CreateAccountFormProps> = ({
  submit,
  error,
  disableForm,
  knownGivenName,
  knownFamilyName,
}) => {
  const [givenName, setGivenName] = useState(knownGivenName || "");
  const [familyName, setFamilyName] = useState(knownFamilyName || "");
  const [shortname, setShortname] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  let firstnameRef = useRef<HTMLInputElement>(null);
  let lastnameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let newErr = error;
    if (error?.includes("to be unique")) {
      newErr = "Email is already registered";
    }
    setErrorMsg(newErr || "");
  }, [error]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (givenName.trim() === "") {
      firstnameRef?.current?.focus();
      return setErrorMsg("Please supply a first name");
    }
    if (familyName.trim() === "") {
      lastnameRef?.current?.focus();
      return setErrorMsg("Please supply a last name");
    }
    submit({
      givenName,
      familyName,
      shortname,
    });
  };

  return (
    <form className="create-account-form" onSubmit={handleSubmit}>
      <div className="name-section">
        <div className="normal-text bold white">WHAT'S YOUR NAME?</div>
        <div className="name-inputs">
          <input
            ref={firstnameRef}
            className="input dark"
            value={givenName || ""}
            name="givenName"
            type="text"
            placeholder="First name"
            onChange={(e) => {
              setGivenName(e.target.value);
              setErrorMsg("");
            }}
            required
            autoFocus={!givenName}
          />
          <input
            ref={lastnameRef}
            className="input dark second-input"
            value={familyName || ""}
            name="LNAME"
            type="text"
            placeholder="Last name"
            onChange={(e) => {
              setFamilyName(e.target.value);
              setErrorMsg("");
            }}
            required
          />
        </div>
      </div>
      <div className="shortname-section">
        <div className="normal-text bold white">CREATE A USERNAME</div>
        <div className="white warning">
          Your personal profile will appear at{" "}
          <strong>hash.ai/@{shortname || "username"}</strong> - please note this
          can't be changed later!
        </div>
        <input
          type="text"
          className={"input dark" + (errorMsg ? " error" : "")}
          value={shortname}
          placeholder={"@username"}
          minLength={4}
          maxLength={24}
          required
          onChange={(e) => {
            setErrorMsg("");
            const { value, error } = setShortnameValue(e.target.value);
            if (error) {
              return setErrorMsg(error);
            } else {
              setShortname(value?.toLowerCase() || "");
            }
          }}
          autoFocus={!!(givenName && familyName)}
        />
      </div>
      <div className="button-and-consent">
        <button type="submit" className="white-button" disabled={disableForm}>
          {"Create Account"}
        </button>
        <div className="consent-disclaimer white">
          By clicking 'Create Account' you agree to our&nbsp;
          <a href="/legal/terms" target="_blank">
            Terms of Service
          </a>
          &nbsp;and&nbsp;
          <a href="/legal/privacy" target="_blank">
            Privacy Policy
          </a>
        </div>
      </div>
      <div className={"error-msg" + (errorMsg ? " active" : "")}>
        {errorMsg}
      </div>
    </form>
  );
};

export default CreateAccountForm;
