import React from "react";
import Head from "next/head";
import { pure } from "recompose";

import removeMarkdown from "src/lib/removeMarkdown";
import { isDev, isProd } from "src/lib/environment";

const HTMLHead = pure(() => {
  return (
    <Head>
      <meta name="apple-mobile-web-app-title" content="HASH" />
      <title>HASH</title>

      {/* Google Tag Manager */}
      <script>{isProd ? `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-MS7H93X');` : ""}</script>
      {/* End Google Tag Manager */}

      <link
        rel="shortcut icon"
        type="image/png"
        href="/assets/img/brand/favicon.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        href="/assets/img/brand/favicon-57.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="72x72"
        href="/assets/img/brand/favicon-72.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="76x76"
        href="/assets/img/brand/favicon-76.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="114x114"
        href="/assets/img/brand/favicon-114.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="120x120"
        href="/assets/img/brand/favicon-120.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="144x144"
        href="/assets/img/brand/favicon-144.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="152x152"
        href="/assets/img/brand/favicon-152.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="180x180"
        href="/assets/img/brand/favicon-180.png"
      />
      <link
        rel="icon"
        sizes="192x192"
        href="/assets/img/brand/favicon-192.png"
      />
      <link
        rel="mask-icon"
        href="/assets/img/brand/safari-pinned-tab.svg"
        color="#0094ff"
      />

      {isDev && <meta name="robots" content="noindex, nofollow" />}

      <meta name="manifest" content="/assets/scripts/site.webmanifest" />
      <meta name="msapplication-TileColor" content="#FFFFFF" />
      <meta
        name="msapplication-TileImage"
        content="/assets/img/brand/favicon-144.png"
      />
      <meta
        name="msapplication-config"
        content="/assets/scripts/browserconfig.xml"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      <script
        async
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=5978b6ce-eef1-4358-98f9-2680c9fbfbe3"
      >
        {" "}
      </script>
    </Head>
    // tslint:enable
  );
});

export default HTMLHead;

export const socialMetadata = (args?: {
  title?: string;
  url?: string;
  description?: string;
  image?: string;
  noIndex?: boolean;
}) => {
  const { title, url, image, noIndex } = args || {};
  const description = removeMarkdown(args?.description);

  const defaultDesc =
    "Integrate live data, construct ontologies, and create shared understanding in a collaborative, open-source workspace.";
  const defaultTitle = "HASH - a workspace for structured knowledge.";
  const defaultImage = "https://cdn-us1.hash.ai/assets/hash-card.png";

  return (
    <>
      <title>{title || defaultTitle}</title>
      {/* Info for twitter card previews */}
      <meta name="twitter:card" content={"summary_large_image"} />
      <meta name="twitter:site" content="@hashintel" />
      <meta name="twitter:title" content={title || defaultTitle} />
      <meta name="twitter:description" content={description || defaultDesc} />
      <meta name="twitter:image" content={image || defaultImage} />
      {/* Twitter end */}

      {/* Facebook */}
      <meta property="og:url" content={url || "https://hash.ai"} />
      <meta property="og:title" content={title || defaultTitle} />
      <meta property="og:description" content={description || defaultDesc} />
      <meta property="og:image" content={image || defaultImage} />
      {/* Facebook end */}

      <meta name="description" content={description || defaultDesc} />

      {noIndex && (
        <>
          <meta name="robots" content="noindex, nofollow" />
          <meta name="googlebot" content="noindex, nofollow, noarchive" />
        </>
      )}
    </>
  );
};
