import { pick } from "lodash";

import { IndexSearchQuery } from "src/lib/globalTypes";
import { paramsToString } from "src/lib/functions";
import { trackEvent } from "src/lib/analytics";
import { useRouter } from "next/router";
import { useUser } from "./useUser";

const typeToSearchType: { [key: string]: string } = {
  behavior: "behaviors",
  dataset: "data",
  schema: "schemas",
  simulation: "models",
};

export const useProjectSearch = () => {
  const [user] = useUser();
  const { query, push } = useRouter();

  const search = (params: IndexSearchQuery) => {
    // We namespace the path under the type rather than using it it as a param
    // We need to keep checks for contentType for legacy links
    let type =
      params.searchType || query.contentType || query.searchType || "all";
    // Catch any instances where the non-plural type is sent
    type = typeToSearchType[(type as string).toLowerCase()] || type;

    if (!params.page) {
      params.page = 1;
    }

    // Legacy provision for old links
    if (query.sort === "dateModified") {
      query.sort = "updatedAt";
    }
    if (query.sort === "datePublished") {
      query.sort = "createdAt";
    }

    // merge any existing query params with the new ones requested
    params = {
      ...query,
      ...params,
    };

    // Choose a different sort method if it's currently relevance
    // and there's no longer a text query to determine relevance
    if (!params.query && params.sort === "relevance") {
      if (query.sort !== "relevance") {
        params.sort = query.sort as IndexSearchQuery["sort"];
      } else {
        params.sort = "updatedAt";
      }
    }

    const searchParams = pick(params, [
      "query",
      "sort",
      "page",
      "perPage",
      "language",
      "subject",
      "spatialCoverage",
      "temporalCoverage",
      "dataFrequency",
      "releasedOnly",
    ]);

    const paramString = paramsToString(searchParams);

    trackEvent(
      {
        category: "Activity",
        action: "Index Search: Main",
        label: paramString,
      },
      user
    );

    push(`/${type === "all" ? "search" : type}?` + paramString);
  };

  return search;
};
