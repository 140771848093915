import React from "react";
import Link from "next/link";
import { Box, BoxTypeMap } from "@mui/material";
import ArrowUpRightLight from "../../../../../public/assets/arrow-up-right-light.svg";
import { OverrideProps } from "@mui/material/OverridableComponent";

export const TextLink: React.FC<
  {
    external?: boolean;
  } & OverrideProps<BoxTypeMap<{}, "a">, "a">
> = ({ href, external, children, sx, ...remainingBoxProps }) => {
  const content = (
    <Box
      {...remainingBoxProps}
      component="a"
      sx={[
        {
          color: "#37434F",
          display: "flex",
          alignItems: "center",
          transition: "all 0.2s ease-in",
          ":hover": { color: "#0775E3" },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...(external && {
        target: "_blank",
        rel: "noopener",
      })}
    >
      <Box
        component="p"
        sx={{
          fontWeight: 500,
          fontSize: "1rem",
          margin: 0,
          marginRight: external ? 1 : undefined,
        }}
      >
        {children}
      </Box>
      {external && <ArrowUpRightLight />}
    </Box>
  );

  return href ? (
    <Link href={href ?? "#"} passHref>
      {content}
    </Link>
  ) : (
    content
  );
};
