import Link from "next/link";
import React, { FunctionComponent, useState, useEffect } from "react";
import { useMutation } from "@apollo/client";

import CheckCircleIcon from "../icons/CheckCircleIcon";
import CloseIcon from "../icons/CloseIcon";
import CompletedRegistrationButtons from "./CompletedRegistrationButtons";
import CreateAccountForm, { CreateAccountInput } from "./CreateAccountForm";
import api from "src/lib/api";
import { MailchimpUser } from "src/lib/globalTypes";
import { Mutation } from "src/lib/graphql/types";
import { addUserMutation } from "src/lib/graphql/user.queries";
import { trackEvent } from "src/lib/analytics";
import { useUser } from "src/hooks/useUser";
import { useRouter } from "next/router";

type EarlyAccessWindowProps = {
  mailchimpUser?: MailchimpUser;
  closeWindow?: () => void;
  signinRedirect?: string;
  prefillSignupEmail?: string;
  lightbox?: boolean;
};

const EarlyAccessWindow: FunctionComponent<EarlyAccessWindowProps> = ({
  mailchimpUser,
  closeWindow,
  signinRedirect,
  prefillSignupEmail,
  lightbox = true,
}) => {
  const [preventScroll, setPreventScroll] = useState(!!lightbox);

  useEffect(() => {
    if (prefillSignupEmail) {
      submitEmail();
    }
  }, []);

  const [user] = useUser();
  const router = useRouter();

  const [email, setEmail] = useState(mailchimpUser?.email || "");
  const [givenName, setGivenName] = useState(
    mailchimpUser?.merge_fields?.FNAME || ""
  );
  const [familyName, setFamilyName] = useState(
    mailchimpUser?.merge_fields?.LNAME || ""
  );

  const [disableForm, setDisableForm] = useState(false);
  const [signupStatus, setSignupStatus] = useState("");
  const [emailInput, setEmailInput] = useState(prefillSignupEmail || "");
  const [mailchimpId, setMailchimpId] = useState(mailchimpUser?.id || "");
  const [step, setStep] = useState<"start" | "register" | "done">(
    mailchimpUser?.email ? "register" : "start"
  );

  const header = {
    start: {
      header: <h2 className="heading-1">{"Create an account"}</h2>,
      text: "Enter your email address to get started",
    },
    register: {
      header: <h2 className="heading-1">Almost there...</h2>,
      text: "A few more details and you're in!",
    },
    done: {
      header: (
        <h2 className="heading-1 success">
          <CheckCircleIcon height={78} width={78} />
          Great!
        </h2>
      ),
      text: (
        <>
          <strong>We've sent a link to your email address.</strong>
          <br />
          Click this to finish creating your account and get started with HASH.
        </>
      ),
    },
  };

  const submitEmail = async (e?: React.FormEvent) => {
    e?.preventDefault();
    setSignupStatus("");
    setDisableForm(true);
    const { data } = await api.post(
      "/subscribe/email",
      {},
      {
        email: emailInput,
        merge_fields: {
          REFERRER:
            localStorage.getItem("referrer") || window.document.referrer,
          SIGNUPLOC: window.location.href,
        },
      }
    );
    if (data.response.status === "subscribed") {
      trackEvent(
        {
          category: "Growth",
          action: "Email Signup",
        },
        user
      );
      setEmail(data.response.email);
      setMailchimpId(data.response.id);
      setGivenName(data.response.merge_fields?.FNAME || "");
      setFamilyName(data.response.merge_fields?.LNAME || "");
      setStep("register");
      return setDisableForm(false);
    } else if (!data?.response?.title) {
      setSignupStatus("Something went wrong ☹️ Please try again later");
    } else if (data.response.title.includes("Invalid Resource")) {
      setSignupStatus("Are you sure? 🤔Please try a different address…");
    } else if (data.response.title.includes("Member Exists")) {
      if (data.response.merge_fields.ACTIVATED !== "Yes") {
        setEmail(data.response.email);
        setGivenName(data.response.merge_fields?.FNAME || "");
        setFamilyName(data.response.merge_fields?.LNAME || "");
        setMailchimpId(data.response.id);
        setStep("register");
      } else if (data.response.email === user?.email) {
        setSignupStatus("You're already logged in to this account!");
      } else {
        setSignupStatus("You've already got an account! Sign in!");
      }
    }
    setTimeout(() => {
      setDisableForm(false);
      setSignupStatus("");
      setEmailInput("");
    }, 3000);
  };

  const [addUser, { data, error, loading }] = useMutation<Mutation>(
    addUserMutation,
    {
      onCompleted: (result) => {
        setStep("done");
        const newUser = result?.addUser;
        if (newUser) {
          trackEvent(
            {
              category: "Growth",
              action: "User Completed Registration: Main",
              label: `${newUser.shortname} - ${newUser.id}`,
            },
            newUser
          );
        }
        setDisableForm(false);
      },
    }
  );

  const createAccount = async (args: CreateAccountInput) => {
    setSignupStatus("");
    setDisableForm(true);
    addUser({
      variables: {
        data: {
          ...args,
          email,
        },
      },
    }).catch(() => setDisableForm(false));
    await api.patch(
      "/subscribe/email",
      {},
      {
        mailchimp_id: mailchimpId,
        merge_fields: {
          FNAME: args.givenName,
          LNAME: args.familyName,
        },
      }
    );
  };

  const redirect = signinRedirect || router.asPath || "/";

  return (
    <div
      className={
        "early-access-overlay content-min-height" +
        (lightbox ? " lightbox" : "")
      }
    >
      <div className={`early-access-window ${step}`}>
        {lightbox && (
          <div onClick={closeWindow} id="close-svg">
            <CloseIcon />
          </div>
        )}
        <div className="mainsite-header">
          {header[step].header}
          <p className="large-text white">{header[step].text}</p>
        </div>
        {step === "start" ? (
          <>
            <div className="signup-status">{signupStatus}</div>
            <form className="early-access-form" onSubmit={submitEmail}>
              <input
                className="input dark"
                type={"email"}
                name={"email"}
                value={emailInput}
                onChange={(e) => {
                  setEmailInput(e.target.value);
                  setDisableForm(false);
                }}
                placeholder={"your.email@example.com"}
                required
              />
              <button
                type="submit"
                className="white-button"
                disabled={disableForm}
              >
                Continue
              </button>
              <p className="already-signed-up normal-text white">
                Already have an account?&nbsp;
                <Link href={`/signin?redirect=${redirect}`}>
                  <a className="text-link" onClick={closeWindow}>
                    Sign in here
                  </a>
                </Link>
                .
              </p>
            </form>
          </>
        ) : step === "register" ? (
          <CreateAccountForm
            submit={createAccount}
            error={error?.message.replace("GraphQL error: ", "")}
            disableForm={disableForm}
            knownGivenName={givenName}
            knownFamilyName={familyName}
          />
        ) : (
          <CompletedRegistrationButtons />
        )}
      </div>
      {step === "start" && (
        <div className="copyright-and-terms-notice normal-text white">
          <span>© HASH {new Date().getFullYear()}</span>
          By continuing, you agree to our{" "}
          <a href="/legal/terms" target="_blank">
            Terms of Service
          </a>{" "}
          and{" "}
          <a href="/legal/privacy" target="_blank">
            Privacy Policy
          </a>
          .
        </div>
      )}
      <style jsx global>{`
        body {
          overflow: ${preventScroll ? "hidden" : "auto"};
        }
      `}</style>
    </div>
  );
};

export default EarlyAccessWindow;
