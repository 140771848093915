import { pick } from "lodash";
import gql from "graphql-tag";
import queryAPI, { queryResponse } from "./connection";
import { AllUserQueryUser } from "src/components/admin/panels/Users";
import { User } from "./types";
import {
  userFieldsFragment,
  orgFieldsFragment,
  userFields,
  userOrOrgProjectResultsFragment,
  projectCardFieldsFragment,
} from "./fragments";

export const meQuery = gql`
  query me($withDatasets: Boolean = false) {
    me {
      ...UserFields
      datasets @include(if: $withDatasets) {
        id
        name
        format
      }
    }
  }
  ${userFieldsFragment}
`;

export const userIdFromEmail = gql`
  query getUserFromEmail($email: String) {
    user(email: $email) {
      id
      shortname
    }
  }
`;

export const getUserQuery = gql`
  query getUser(
    $shortname: String
    $email: String
    $id: ID
    $withProjects: Boolean = false
  ) {
    user(shortname: $shortname, email: $email, id: $id) {
      ...UserFields
      projects @include(if: $withProjects) {
        ...UserOrOrgProjectResultsFields
      }
    }
  }
  ${userFieldsFragment}
  ${userOrOrgProjectResultsFragment}
`;

export const updateMeMutation = gql`
  mutation updateMe($data: UserUpdateInput!) {
    updateMe(data: $data) {
      ...UserFields
    }
  }
  ${userFieldsFragment}
`;

export const addUserMutation = gql`
  mutation addUser($data: UserCreationInput!) {
    addUser(data: $data) {
      id
      email
      shortname
      givenName
      familyName
    }
  }
`;

export const deleteUserMutation = gql`
  mutation deleteUser($email: String!) {
    deleteUser(email: $email)
  }
`;

export const sendLoginReminderMutation = gql`
  mutation sendLoginReminder($email: String!) {
    sendLoginReminder(email: $email)
  }
`;

export const allUsersQuery = gql`
  query allUsers(
    $page: Int
    $sort: UserSortOption
    $sortDirection: SortDirection
    $search: String
    $filter: String
  ) {
    users(
      page: $page
      sort: $sort
      sortDirection: $sortDirection
      search: $search
      filter: $filter
    ) {
      totalCount
      page
      sort
      sortDirection
      filter
      users {
        id
        givenName
        familyName
        fullName
        archived
        email
        biography
        location
        url
        knowsLanguage
        telephone
        shortname
        image
        projectCount
        role {
          id
          name
          description
        }
        createdAt
        updatedAt
        lastLogin
      }
    }
  }
`;

export const userInputFields = (user: User | AllUserQueryUser) => {
  return pick(user, [
    "givenName",
    "familyName",
    "biography",
    "location",
    "url",
    "knowsLanguage",
    "telephone",
  ]);
};

export const getSelf = async (
  cookieString?: string
): Promise<User | undefined> => {
  const query = `
    query me {
      me {
        ${userFields}
      }
    }
  `;

  return await queryAPI(query, {}, cookieString).then(
    (response: queryResponse) => response.data.me
  );
};

export const getUserOrOrgQuery = gql`
  query getUserOrOrg(
    $shortname: String
    $id: ID
    $withProjects: Boolean = false
    $page: Int = 1
    $sort: SortOption = updatedAt
    $types: [ProjectTypeName!]
  ) {
    userOrOrg(shortname: $shortname, id: $id) {
      __typename
      ... on User {
        ...UserFields
        projects(page: $page, sort: $sort, types: $types)
          @include(if: $withProjects) {
          ...UserOrOrgProjectResultsFields
        }
        projectCount
        projectTypes @include(if: $withProjects)
      }
      ... on Org {
        ...OrgFields
        projects(page: $page, sort: $sort, types: $types)
          @include(if: $withProjects) {
          ...UserOrOrgProjectResultsFields
        }
        projectCount
        projectTypes @include(if: $withProjects)
      }
    }
  }
  ${userFieldsFragment}
  ${orgFieldsFragment}
  ${userOrOrgProjectResultsFragment}
`;

export const updateUserMutation = gql`
  mutation UpdateUser($id: ID!, $data: UserUpdateInput!) {
    updateUser(id: $id, data: $data) {
      ...UserFields
    }
  }
  ${userFieldsFragment}
`;

export const registerPaymentMethodMutation = gql`
  mutation registerPaymentMethod {
    registerPaymentMethod
  }
`;

export const setDefaultPaymentMethodMutation = gql`
  mutation setDefaultPaymentMethod($id: ID!) {
    setDefaultPaymentMethod(id: $id) {
      ...UserFields
    }
  }
  ${userFieldsFragment}
`;

export const rolesQuery = gql`
  query userRoles {
    roles {
      id
      name
      description
    }
  }
`;
