import React, {
  FunctionComponent,
  useState,
  FormEvent,
  useRef,
  useEffect,
} from "react";
import { useProjectSearch } from "src/hooks/useProjectSearch";
import { useRouter } from "next/router";

type SearchBarProps = {
  searchText?: string;
};

const SearchBar: FunctionComponent<SearchBarProps> = ({ searchText }) => {
  const [textQuery, setQuery] = useState(searchText || "");
  const search = useProjectSearch();

  const router = useRouter();
  const textInput = useRef<HTMLInputElement | null>(null);
  const isHomepage = !router?.pathname.includes("search");
  const isMobile =
    typeof navigator !== "undefined" && navigator?.userAgent?.match(/mobi/i);

  useEffect(() => {
    const input = textInput.current;
    if (input && !isMobile && isHomepage) {
      // Auto-focus search bar on homepage for desktop devices
      input.focus();
    }
  }, []);

  useEffect(() => {
    if (router.query.query !== textQuery) {
      setQuery((router.query.query as string) || "");
    }
  }, [router.query]);

  const submitSearch = () =>
    search({
      query: textQuery.trim(),
      sort: "relevance",
    });

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    submitSearch();
  };

  return (
    <div className="searchbar">
      <form className="searchbar-input" onSubmit={onSubmit}>
        <input
          type="text"
          className="input normal-text"
          placeholder="Search for anything"
          value={textQuery}
          onChange={(e) => setQuery(e.target.value)}
          onBlur={() => isMobile && submitSearch()}
          ref={textInput}
        />
        <button type="submit">
          <img src="/assets/magnify.svg" alt="Search" />
        </button>
      </form>
    </div>
  );
};

export default SearchBar;
