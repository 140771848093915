import Link from "next/link";
import React, {
  memo,
  useState,
  FunctionComponent,
  useEffect,
  useRef,
  forwardRef,
} from "react";
import { useMediaQuery } from "react-responsive";
import { useRouter } from "next/router";
import {
  Box,
  ButtonProps,
  experimental_sx,
  Fade,
  Slide,
  styled,
  useScrollTrigger,
  useTheme,
} from "@mui/material";
import { User } from "src/lib/graphql/types";
import Logo from "../../logos/HashLogo";
import SearchBar from "../../index/SearchBar";
import AccountDropdown from "./AccountDropdown";
import { SideBarUrlsData } from "src/pages/docs/[...docsSlug]";
import { DesktopNav } from "./DesktopNav";
import { NavButton } from "./shared/NavButton";
import { CloseLightIcon } from "src/components/icons/CloseLightIcon";
import { BarsLightIcon } from "src/components/icons/BarsLightIcon";
// import {
//   // HIRING_BANNER_HEIGHT,
//   HiringBanner,
// } from "src/components/HiringBanner";
import { isEventTargetANode } from "./shared/util";
import { apiUrl } from "src/lib/environment";

export const MOBILE_NAV_MAX_SCREEN_WIDTH = 850;

const DisplayDesktop = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down(MOBILE_NAV_MAX_SCREEN_WIDTH)]: {
    display: "none",
  },
}));

const DisplayMobile = styled(Box)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.down(MOBILE_NAV_MAX_SCREEN_WIDTH)]: {
    display: "block",
  },
}));

const PrimaryNavButton = styled(
  forwardRef<HTMLButtonElement, ButtonProps>((props, forwardedRef) => (
    <NavButton
      {...props}
      className={[props.className, "primary-button"].join(" ")}
      ref={forwardedRef}
    />
  ))
)(({ theme }) =>
  experimental_sx({
    backgroundColor: "#387FE9",
    color: theme.palette.common.white,
    "&.primary-button": {
      fontSize: "16px",
      padding: theme.spacing(1.5, 2),
    },
  })
);

const WIDGET_Z_INDEX = 999998;

const MOBILE_MENU_Z_INDEX = WIDGET_Z_INDEX + 1;

export const NAVBAR_Z_INDEX = MOBILE_MENU_Z_INDEX + 1;

export const DESKTOP_NAVBAR_HEIGHT = 78.2;

export const MOBILE_NAVBAR_HEIGHT = 69.2;

const IDLE_NAVBAR_TIMEOUT_MS = 3000;

export type MobileSideDrawerLinks = {
  title?: string;
  links?: SideBarUrlsData;
};

type NavbarProps = {
  mobileSideDrawerContents: any;
  mobileSideDrawerLinks?: MobileSideDrawerLinks;
  user?: User | null;
  location?: string;
  searchText?: string;
  signup: () => void;
};

const preventRender = (prevProps: NavbarProps, nextProps: NavbarProps) => {
  if (
    prevProps.location !== nextProps.location ||
    (prevProps.user && !nextProps.user) ||
    (!prevProps.user && nextProps.user) ||
    prevProps.mobileSideDrawerContents !== nextProps.mobileSideDrawerContents ||
    prevProps.mobileSideDrawerLinks !== nextProps.mobileSideDrawerLinks
  ) {
    return false;
  }
  return true;
};

export const Navbar: FunctionComponent<NavbarProps> = memo(
  ({ user, signup, location = "Index", searchText }) => {
    const router = useRouter();
    const theme = useTheme();

    const navbarRef = useRef<HTMLDivElement>(null);

    const [isNavbarHidden, setIsNavbarHidden] = useState<boolean>(false);
    const hideNavbarTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

    const prevIsCursorInNavbarRef = useRef<boolean>(false);

    const [isCursorInNavbar, setIsCursorInNavbar] = useState<boolean>(false);

    useEffect(() => {
      prevIsCursorInNavbarRef.current = isCursorInNavbar;
    }, [isCursorInNavbar]);

    useEffect(() => {
      const mousemove = ({ target }: MouseEvent) => {
        if (prevIsCursorInNavbarRef.current) {
          if (
            isEventTargetANode(target) &&
            !navbarRef.current?.contains(target)
          ) {
            setIsCursorInNavbar(false);
          }
        } else {
          if (
            isEventTargetANode(target) &&
            navbarRef.current?.contains(target)
          ) {
            setIsCursorInNavbar(true);
          }
        }
      };
      document.addEventListener("mousemove", mousemove);

      return () => {
        document.removeEventListener("mousemove", mousemove);
      };
    }, []);

    const [
      desktopNavDropdownIsOpen,
      setDesktopNavDropdownIsOpen,
    ] = useState<boolean>(false);
    const [
      desktopCreateDropdownIsOpen,
      setDesktopCreateDropdownIsOpen,
    ] = useState<boolean>(false);
    const [
      desktopAccountDropdownIsOpen,
      setDesktopAccountDropdownIsOpen,
    ] = useState<boolean>(false);

    const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState<boolean>(false);

    useEffect(() => {
      setMobileMenuIsOpen(false);
    }, [router.asPath]);

    const [idleScrollPosition, setIdleScrollPosition] = useState<boolean>(
      false
    );

    const [scrollY, setScrollY] = useState<number>(0);

    useEffect(() => {
      let timer: NodeJS.Timeout | undefined = undefined;
      const onScroll = () => {
        setScrollY(window.scrollY);
        setIdleScrollPosition(false);
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {
          setIdleScrollPosition(true);
        }, IDLE_NAVBAR_TIMEOUT_MS);
      };

      onScroll();

      window.addEventListener("scroll", onScroll);

      return () => {
        window.removeEventListener("scroll", onScroll);
      };
    }, []);

    const trigger = useScrollTrigger();

    const isScrollYAtTopOfPage = scrollY === 0;

    const isDropdownOpen =
      desktopNavDropdownIsOpen ||
      desktopCreateDropdownIsOpen ||
      desktopAccountDropdownIsOpen;

    const isDisplayingSearchbar = location === "Index";

    const isSigninPage = router.pathname === "/signin";

    const shouldHideNavbar =
      (trigger || (idleScrollPosition && !isScrollYAtTopOfPage)) &&
      !isDisplayingSearchbar &&
      !isCursorInNavbar &&
      !isDropdownOpen &&
      !mobileMenuIsOpen;

    useEffect(() => {
      if (hideNavbarTimer.current) {
        clearTimeout(hideNavbarTimer.current);
      }
      if (shouldHideNavbar) {
        hideNavbarTimer.current = setTimeout(
          () => setIsNavbarHidden(true),
          500
        );
      } else {
        setIsNavbarHidden(false);
      }
    }, [shouldHideNavbar]);

    const isMobileSize = useMediaQuery({
      query: `(max-width: ${MOBILE_NAV_MAX_SCREEN_WIDTH}px)`,
    });

    useEffect(() => {
      if (isMobileSize) {
        if (desktopNavDropdownIsOpen) {
          setDesktopNavDropdownIsOpen(false);
        }
        if (desktopCreateDropdownIsOpen) {
          setDesktopCreateDropdownIsOpen(false);
        }
        if (desktopAccountDropdownIsOpen) {
          setDesktopAccountDropdownIsOpen(false);
        }
      } else {
        if (mobileMenuIsOpen) {
          setMobileMenuIsOpen(false);
        }
      }
    }, [
      isMobileSize,
      mobileMenuIsOpen,
      desktopNavDropdownIsOpen,
      desktopCreateDropdownIsOpen,
      desktopAccountDropdownIsOpen,
    ]);

    let redirectLink: string = "";
    if (!user) {
      redirectLink = "/signin?redirect=" + router.asPath;
    }

    const isHomePage = router.pathname === "/";

    // @todo set back to the import variable of the same name when showing HiringBanner
    const HIRING_BANNER_HEIGHT = 0;

    const isDisplayingHiringBanner =
      isHomePage && scrollY < HIRING_BANNER_HEIGHT && !mobileMenuIsOpen;

    const isBorderBottomTransparent = isScrollYAtTopOfPage && !mobileMenuIsOpen;

    const isBoxShadowTransparent =
      isBorderBottomTransparent || mobileMenuIsOpen || isNavbarHidden;

    const additionalTopOffset = isDisplayingHiringBanner
      ? -scrollY
      : -HIRING_BANNER_HEIGHT;

    const navbarHeight =
      (isMobileSize ? MOBILE_NAVBAR_HEIGHT : DESKTOP_NAVBAR_HEIGHT) +
      (isDisplayingHiringBanner ? HIRING_BANNER_HEIGHT : 0);

    const hiddenNavbarTopOffset = -1 * navbarHeight;

    return (
      <Box
        sx={{
          width: "100%",
          position: "absolute",
          zIndex: NAVBAR_Z_INDEX,
        }}
        className={`navheader ${location} ${user ? "user" : ""}`}
      >
        <Box
          ref={navbarRef}
          sx={{
            maxHeight:
              (isMobileSize ? MOBILE_NAVBAR_HEIGHT : DESKTOP_NAVBAR_HEIGHT) +
              HIRING_BANNER_HEIGHT,
            width: "100%",
            position: "fixed",
            top:
              (isNavbarHidden ? hiddenNavbarTopOffset : 0) +
              additionalTopOffset,
            zIndex: NAVBAR_Z_INDEX,
            backgroundColor: theme.palette.common.white,
            transition: [
              theme.transitions.create("top", { duration: 300 }),
              theme.transitions.create(
                [
                  "padding-top",
                  "padding-bottom",
                  "box-shadow",
                  "border-bottom-color",
                  "background-color",
                ].flat()
              ),
            ]
              .flat()
              .join(", "),
            borderBottomStyle: "solid",
            borderBottomColor: isBorderBottomTransparent
              ? "transparent"
              : "#dfe7ec",
            borderBottomWidth: 1,
            /** @todo: find way to make drop-shadow appear behind mobile navigation links */
            boxShadow: isBoxShadowTransparent
              ? "none"
              : "1px 2.8px 3px rgba(60, 60, 72, 0.054), 6.7px 6.7px 5.3px rgba(60, 60, 72, 0.028), 12.5px 12.5px 10px rgba(60, 60, 72, 0.035)",
          }}
        >
          {/*<HiringBanner />*/}
          <Box
            sx={(theme) => ({
              paddingTop: 2,
              paddingBottom: 2.25,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              [theme.breakpoints.down(992)]: {
                maxWidth: "unset",
              },
            })}
            className="container"
          >
            <Box
              sx={{ display: "flex", alignItems: "center" }}
              className="links normal-text bold"
            >
              <Link href="/">
                <a className="image-link">
                  <div className={location !== "Index" ? "" : "desktop"}>
                    <Logo size={1.5} color={"black"} letters />
                  </div>
                  {isDisplayingSearchbar && (
                    <div className="mobile">
                      <Logo size={2.2} color={"black"} letters={false} />
                    </div>
                  )}
                </a>
              </Link>
            </Box>
            {isDisplayingSearchbar ? (
              <SearchBar searchText={searchText} />
            ) : null}
            <Box display="flex" alignItems="center">
              <DisplayMobile>
                <NavButton
                  sx={{ color: "#64778C", fontSize: 16 }}
                  disableRipple
                  onClick={() => setMobileMenuIsOpen((prev) => !prev)}
                  endIcon={
                    mobileMenuIsOpen ? (
                      <CloseLightIcon sx={{ height: "16px" }} />
                    ) : (
                      <BarsLightIcon sx={{ height: "16px" }} />
                    )
                  }
                >
                  {mobileMenuIsOpen ? "Close" : "Menu"}
                </NavButton>
              </DisplayMobile>
              <DisplayDesktop sx={{ display: "flex", alignItems: "center" }}>
                {user ? (
                    <AccountDropdown
                      desktopAccountDropdownIsOpen={
                        desktopAccountDropdownIsOpen
                      }
                      setDesktopAccountDropdownIsOpen={
                        setDesktopAccountDropdownIsOpen
                      }
                    />
                ) : (
                  <>
                    <Link href={redirectLink} passHref>
                      <Fade in={!isSigninPage}>
                        <Box component="a" sx={{ marginRight: 1 }}>
                          <NavButton
                            sx={{ padding: (theme) => theme.spacing(1.5, 2) }}
                          >
                            Sign in
                          </NavButton>
                        </Box>
                      </Fade>
                    </Link>
                  </>
                )}
              </DisplayDesktop>
            </Box>
          </Box>
        </Box>
        <Slide in={mobileMenuIsOpen}>
          <Box
            sx={{
              zIndex: MOBILE_MENU_Z_INDEX,
              marginTop: `${navbarHeight}px`,
              position: "fixed",
              background: (theme) => theme.palette.common.white,
              top: 0,
              left: 0,
              width: "100%",
              height: `calc(100% - ${navbarHeight}px)`,
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              p={5}
              flexShrink={0}
              display="flex"
              justifyContent={user ? "center" : "space-between"}
              alignItems="center"
            >
              <Link href={user ? `${apiUrl}/logout` : redirectLink} passHref>
                <Fade in={!isSigninPage}>
                  <Box
                    component="a"
                    sx={{
                      marginRight: 1,
                      maxWidth: 175,
                      flexGrow: 1,
                    }}
                  >
                    <NavButton
                      sx={{ padding: 2, width: "100%" }}
                      disabled={isSigninPage}
                    >
                      {user ? "Log out" : "Sign in"}
                    </NavButton>
                  </Box>
                </Fade>
              </Link>
            </Box>
          </Box>
        </Slide>
      </Box>
    );
  },
  preventRender
);
