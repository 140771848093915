import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

let host: string = `${
  typeof window === "undefined"
    ? (process.env.SERVER_HOST as string)
    : window.location.origin
}`;

const axiosConfig: AxiosRequestConfig = { withCredentials: true };

if (
  process.env.NODE_ENV === "development" ||
  (typeof window !== "undefined"
    ? window.location.origin === "localhost"
    : false)
) {
  // The development axios config:

  axiosConfig.baseURL = host;
  axiosConfig.proxy = {
    host,
    port: 3000,
  };
} else {
  // The production axios config:
  axiosConfig.baseURL = host;
}

let axiosInstance = axios.create(axiosConfig);
let api: any = {};

api.get = (
  path: string,
  params: object,
  Cookie: string | undefined
): Promise<AxiosResponse<any>> => {
  if (typeof window === "undefined" && Cookie) {
    return axiosInstance.get(`/api${path}`, {
      headers: { Cookie },
      params,
    });
  } else {
    return axiosInstance.get(`/api${path}`, { params });
  }
};

api.post = (
  path: string,
  params: object,
  data: any,
  Cookie: string | undefined
): Promise<AxiosResponse<any>> => {
  if (typeof window === "undefined" && Cookie) {
    return axiosInstance.post(`/api${path}`, data, {
      headers: { Cookie },
      params,
    });
  } else {
    return axiosInstance.post(`/api${path}`, data, { params });
  }
};

api.put = (
  path: string,
  params: object,
  data: any,
  Cookie: string | undefined
): Promise<AxiosResponse<any>> => {
  if (typeof window === "undefined" && Cookie) {
    return axiosInstance.put(`/api${path}`, data, {
      headers: { Cookie },
      params,
    });
  } else {
    return axiosInstance.put(`/api${path}`, data, { params });
  }
};

api.patch = (
  path: string,
  params: object,
  data: any,
  Cookie: string | undefined
): Promise<AxiosResponse<any>> => {
  if (typeof window === "undefined" && Cookie) {
    return axiosInstance.patch(`/api${path}`, data, {
      headers: { Cookie },
      params,
    });
  } else {
    return axiosInstance.patch(`/api${path}`, data, { params });
  }
};

api.delete = (
  path: string,
  params: object,
  Cookie: string | undefined
): Promise<AxiosResponse<any>> => {
  if (typeof window === "undefined" && Cookie) {
    return axiosInstance.delete(`/api${path}`, {
      headers: { Cookie },
      params,
    });
  } else {
    return axiosInstance.delete(`/api${path}`, { params });
  }
};

export default api;
