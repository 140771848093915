import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const BarsLightIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fill="currentColor"
        d="M1 2.75C1 2.34375 1.3125 2 1.75 2H14.25C14.6562 2 15 2.34375 15 2.75C15 3.1875 14.6562 3.5 14.25 3.5H1.75C1.3125 3.5 1 3.1875 1 2.75ZM1 7.75C1 7.34375 1.3125 7 1.75 7H14.25C14.6562 7 15 7.34375 15 7.75C15 8.1875 14.6562 8.5 14.25 8.5H1.75C1.3125 8.5 1 8.1875 1 7.75ZM14.25 13.5H1.75C1.3125 13.5 1 13.1875 1 12.75C1 12.3438 1.3125 12 1.75 12H14.25C14.6562 12 15 12.3438 15 12.75C15 13.1875 14.6562 13.5 14.25 13.5Z"
      />
    </SvgIcon>
  );
};
