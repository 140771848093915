import { getDataFromTree } from "@apollo/client/react/ssr";
import * as Sentry from "@sentry/node";
import withApollo from "next-with-apollo";
import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";

import fragmentTypes from "./graphql/fragmentTypes.json";
import { graphqlUrl } from "./environment";

const errorLink = onError(({ graphQLErrors, operation }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, extensions, path }) => {
      Sentry.withScope((scope) => {
        const error = new Error("GraphQL Error: " + path?.[0].toString());
        scope.setExtra("Exception", extensions?.exception);
        scope.setExtra("Location", path);
        scope.setExtra("Query", operation.query?.loc?.source?.body);
        scope.setExtra("Variables", operation.variables);
        error.message = `GraphQL error - ${path?.[0].toString()} - ${message}`;
        Sentry.captureException(error);
      });
    });
});

export default withApollo(
  ({ ctx, initialState }: any) => {
    const cookieString = ctx && ctx.req ? ctx!.req.cookieString : undefined;

    const uploadLink = createUploadLink({
      uri: graphqlUrl,
      credentials: "include",
      headers: {
        Cookie: cookieString,
      },
    });

    // Casting until a fix in @types/apollo-upload-client is available
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/47369
    const link = ApolloLink.from([
      (errorLink as any) as ApolloLink,
      (uploadLink as any) as ApolloLink,
    ]);

    return new ApolloClient({
      cache: new InMemoryCache(fragmentTypes).restore(initialState || {}),
      name: "hash.ai",
      ssrMode: !process.browser,
      link,
    });
  },
  { getDataFromTree }
);
